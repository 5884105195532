import { FieldValues, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { Col, Row } from "react-bootstrap";
import { IClient } from "../../../interfaces/IClient";
import { useState } from "react";
import FileInput from "../../../components/form/FileInput";
import RadioGroupInput from "../../../components/form/RadioGroupInput";
import InvoiceService from "../../../services/InvoiceService";
import { IInvoiceCreatedInfo } from "../../../interfaces/IInvoiceCreatedInfo";
import { IWithMessageInfo } from "../../../interfaces/IWithMessage";
import TextInput from "../../../components/form/TextInput";
import NumberValidator from "../../../validators/NumberValidator";
import { Scanner, useDevices } from "@yudiel/react-qr-scanner";
import SelectInput from "../../../components/form/SelectInput";
import FindInfo from "./FindInfo";
import { stories } from "../constants/StoreConstants";
import CheckboxGroupInput from "../../../components/form/CheckboxGroupInput";

interface IRegister {
  client: IClient;
  onSubmitHandler: Function;
}

export const RegisterForm = ({ client, onSubmitHandler }: IRegister) => {
  const [isLoading, setIsLoading] = useState(false);
  const [readQRCode, setReadQRCode] = useState(false);
  const devices = useDevices();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      fiscalCode: "",
      file: "",
      storeType: 0,
      storeName: "",
      totalProducts: undefined,
      hasProduct: false
    },
    shouldUseNativeValidation: true,
    shouldFocusError: true,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);

    const params = new FormData();
    params.append('token', client.token);
    params.append('fiscalCode', data["fiscalCode"]);
    params.append('file', data["file"][0]);
    params.append('storeType', data["storeType"]);
    params.append('storeName', data["storeName"]);
    params.append('totalProducts', data["totalProducts"]);
    params.append('hasProduct', data["hasProduct"]);

    const res = await InvoiceService.add(params);
    if (res.success) {
      reset();
      setIsLoading(false);
      onSubmitHandler(res?.body as IInvoiceCreatedInfo);
    } else {
      const keys = Object.keys(res.namespacedMessages);

      if (keys.length === 0) {
        setError("fiscalCode", { message: res.globalMessages[0].defaultMessage ?? 'Não foi possível salvar a nota fiscal', type: "value" });
      }
      else {
        keys.forEach((el) => {
          const errors = res.namespacedMessages[el] as IWithMessageInfo[];
          if (errors.length > 0) {
            const error = errors[0];
            const key = error.fieldName as "fiscalCode" | "file" | "storeType" | "storeName" | "totalProducts" | "hasProduct";
            setError(key, { message: error.defaultMessage, type: "value" });
          }
        });
      }
      setIsLoading(false);
    }
  };

  const getTitleStorieInput = () => {
    if (watch("storeType").toString() === "1") {
      return "Loja onde foi realizada a compra?";
    }
    else if (watch("storeType").toString() === "2") {
      return "Loja onde foi retirada a compra";
    }
    return "";
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="form_note" encType="multipart/form-data">
      <h2 className="form_title title mb-4 text-center text-md-start">
        Preencha e Concorra!
      </h2>
      <Row>
        <Col>
          <RadioGroupInput
            label="Onde foi feita a compra?"
            attribute="storeType"
            values={[{ label: "Loja Física", value: 1 }, { label: "Ecommerce / TeleVendas", value: 2 }]}
            register={register}
            control={control}
            rules={{
              required: { value: true, message: "Campo obrigatório" },
            }}
          />
        </Col>
        <Col>
          <div className=" imgs_helpers">
            <h4 className="form_title title text-capitalize">Exemplo:</h4>
            {(watch("storeType").toString() === "2") && (
              <Image
                src="./images/nota-ecommerce-ex.jpg"
                className="img-fluid"
              />)}
            {watch("storeType").toString() === "1" && (
              <Image
                src="./images/nota-fisica-ex.jpg"
                className="img-fluid"
              />)}
          </div>

        </Col>
      </Row>
      {watch("storeType") > 0 && (
        <SelectInput
          label={getTitleStorieInput()}
          attribute="storeName"
          values={stories}
          register={register}
          control={control}
          rules={{
            required: { value: true, message: "Campo obrigatório" },
          }}
        />)}

      <FileInput
        label="Nota Fiscal"
        attribute="file"
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
        }}
      />
      <p className="helper-text">
        Envie foto em boa qualidade e com os dados legíveis. Limite de 5mb
      </p>

      {devices.length > 0 && readQRCode && (
        <div className="scan_window">
          <Scanner
            onScan={(result) => {
              const code = result[0].rawValue.split('=');
              let codeStr = "";
              if (code.length > 0) {
                codeStr = code[1];
              }
              else {
                codeStr = code[0];
              }

              codeStr = codeStr ? codeStr.split('|')[0] : 'QR Code não é de uma nota fiscal válida';

              setValue("fiscalCode", codeStr);
              setReadQRCode(false);
            }}
          />
        </div>
      )}
      <TextInput
        label="Chave de acesso da Nota Fiscal"
        attribute="fiscalCode"
        register={register}
        control={control}
        //errors={errors}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          minLength: { value: 44, message: "Mínimo de 44 caracteres" },
          maxLength: {
            value: 50,
            message: "Máximo de 50 caracteres",
          },
          validate: (data: string) => {
            return !NumberValidator.onlyDigits(data)
              ? "Deve conter apenas números"
              : true;
          },
        }}
        maxLength={50}
        placeholder={"Código da nota fiscal"}
      />
      {devices.length > 0 && (
        <Row className="mt-1 mb-4">
          <Col>
            <Button
              variant="primary"
              onClick={() => setReadQRCode(!readQRCode)}
              className="btn-lg w-100"
            >
              {readQRCode ? "Cancelar" : "Ler QR Code"}
            </Button>
          </Col>
        </Row>
      )}
      <FindInfo />


      <TextInput
        label="Quantidade de itens Suprema"
        attribute="totalProducts"
        register={register}
        control={control}
        type="number"
        rules={{
          required: { value: true, message: "Campo obrigatório" },
          min: { value: 1, message: "Mínimo de 1 produto" },
        }}
        placeholder={"Digite a quantidade de itens"}
      />

      <RadioGroupInput
        label="Possui algum item Farinha de Trigo Select ou Farina de 5kg?"
        attribute="hasProduct"
        values={[{ label: "Sim", value: true }, { label: "Não", value: false }]}
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Campo obrigatório" },
        }}
      />

      <Row>
        <Col>
          <Button
            variant="secondary"
            type="submit"
            className="btn-lg w-100 mt-2"
            disabled={isLoading}
          >
            {isLoading ? 'Enviando…' : 'ENVIAR'}
          </Button>
        </Col>
      </Row>

      <CheckboxGroupInput
        label=""
        attribute="agree"
        values={[{ label: "Lí e concordo com os <a href='https://bungeetenda.com.br/regulamento' target='_blank'>termos e condições</a>", value: true }]}
        register={register}
        control={control}
        rules={{
          required: { value: true, message: "Você deve aceitar os termos e condições" }
        }}
      />
    </Form>
  );
};

