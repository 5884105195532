import { Button, Col, Row, Spinner, Alert } from "react-bootstrap";
import { IClient } from "../../../interfaces/IClient";
import { useNavigate } from "react-router-dom";

interface IProps {
  clientIsLoading: boolean;
  client: IClient;
  onRemoveClient: Function;
  onGoToRegister: Function;
}

const ActionsLogedUser = ({
  clientIsLoading,
  client,
  onRemoveClient,
  onGoToRegister,
}: IProps) => {
  const navigate = useNavigate();
  const getName = () => {
    if (!client) return "";

    const names = client.name?.split(" ");
    const name = names?.map((value, index) => {
      if (index < 2) return value;
    });
    return name?.join(" ").trim();
  };

  const onGoToGame = () => {
    navigate("/jogo", { state: { client } });
  };

  return (
    <div className="form_preregister logged_user">
      <header className="preform_header">
        <h2 className="form_title title">Olá {getName()},</h2>
        <p className="text-uppercase">Deseja cadastrar novas notas fiscais?</p>
      </header>
      <Row>
        
        <Col xs="12"  lg="6" >
          <Button
            variant="secondary"
            onClick={() => onGoToRegister()}
            className="btn-lg w-100 mb-3 mb-lg-0 text-uppercase"
          >
            Cadastrar Nova nota
          </Button>
        </Col>
        {clientIsLoading && (
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!clientIsLoading && client.countOpportunitties > 0 && (
          <Col  xs="12"  lg="6" >
            <Button
              variant="secondary"
              onClick={() => onGoToGame()}
              className="btn-lg w-100 "
            >
              ABRIR CAIXAS DA SORTE
            </Button>
            <Alert variant="info" className="mt-1">
              <small>{`Você possui ${client.countOpportunitties} caixas`}</small>
            </Alert>
          </Col>
        )}
        <Col className="mt-2 mt-lg-0"  xs="12"  lg="6" >
          <Button
            variant="primary"
            onClick={() => onRemoveClient()}
            className="btn-lg w-100 mt-2 mt-lg-0 "
          >
            Não sou eu
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ActionsLogedUser;
