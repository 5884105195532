export interface IStore {
    label: string;
    value: string | boolean | number;
};

export const stories:Array<IStore> = [
    { label: "LOJA - 05 CAMP - CAMPINAS (CEASA)/CAPITAL - SP - 01.157.555/0010-03", value: "LOJA - 05" }, 
    { label: "LOJA - 03 - CAMPINAS (AMOREIRAS)/CAPITAL - SP - 01.157.555/0009-61", value: "LOJA - 03" }, 
    { label: "LOJA - 06 - ATLANTICA/CAPITAL - SP - 01.157.555/0008-80", value: "LOJA - 06" }, 
    { label: "LOJA - 08 - SAO JOSE DOS CAMPOS/VALE PARNAIBA - 01.157.555/0003-76", value: "LOJA - 08" }, 
    { label: "LOJA - 09 - GUARAPIRANGA/CAPITAL - SP - 01.157.555/0006-19", value: "LOJA 09" },
    { label: "LOJA - 13 - GUARATINGUETA/VALE PARNAIBA - 01.157.555/0015-00", value: "LOJA 13" },
    { label: "LOJA - 14 - GUARULHOS BOM SUCESSO/CAPITAL - SP - 01.157.555/0016-90", value: "LOJA 14" },
    { label: "LOJA - 15 - TAUBATE/VALE PARNAIBA - 01.157.555/0017-71", value: "LOJA 15" },
    { label: "LOJA - 16 - CARAPICUIBA/CAPITAL - SP - 01.157.555/0018-52", value: "LOJA 16" },
    { label: "LOJA - 17 - DIADEMA/CAPITAL - SP - 01.157.555/0020-77", value: "LOJA 17" },
    { label: "LOJA - 18 - JACAREI/VALE PARNAIBA - 01.157.555/0021-58", value: "LOJA 18" },
    { label: "LOJA - 20 - ITU/INTERIOR - SP - 01.157.555/0022-39", value: "LOJA 20" },
    { label: "LOJA - 25 - BAURU/OESTE - SP - 01.157.555/0025-81", value: "LOJA 25" },
    { label: "LOJA - 27 - SANTA BARBARA D'OESTE/CAPITAL - SP - 01.157.555/0027-43", value: "LOJA 27" },
    { label: "LOJA - 28 - INDAIATUBA/CAPITAL - SP - 01.157.555/0028-24", value: "LOJA 28" },
    { label: "LOJA - 30 - MOGI GUACU/CAPITAL - SP - 01.157.555/0030-49", value: "LOJA 30" },
    { label: "LOJA - 31 - SÃO CARLOS/INTERIOR - SP - 01.157.555/0031-20", value: "LOJA 31" },
    { label: "LOJA - 32 - SUMARE/CAPITAL - SP - 01.157.555/0032-00", value: "LOJA 32" },
    { label: "LOJA - 33 - ITANHAEM/CAPITAL - SP - 01.157.555/0033-91", value: "LOJA 33" },
    { label: "LOJA - 34 - PINDAMONHANGABA/VALE PARNAIBA - 01.157.555/0034-72", value: "LOJA 34" },
    { label: "LOJA - 35 - BOTUCATU/OESTE - SP - 01.157.555/0035-53", value: "LOJA 35" },
    { label: "LOJA - 36 - SALTO/INTERIOR - SP - 01.157.555/0036-34", value: "LOJA 36" },
    { label: "LOJA - 37 - PIRACICABA/CAPITAL - SP - 01.157.555/0037-15", value: "LOJA 37" },
    { label: "LOJA - 38 - RIBEIRAO PRETO/INTERIOR - SP - 01.157.555/0038-04", value: "LOJA 38" },

    { label: "LOJA - 39 - HORTOLANDIA/CAPITAL - SP - 01.157.555/0039-87", value: "LOJA 39" },
    { label: "LOJA - 41 - FERRAZ DE VASCONCELOS/CAPITAL - SP - 01.157.555/0041-00", value: "LOJA 41" },
    { label: "LOJA - 43 - ITAQUAQUECETUBA/CAPITAL - SP - 01.157.555/0043-63", value: "LOJA 43" },
    { label: "LOJA - 44 - SUZANO/CAPITAL - SP - 01.157.555/0044-44", value: "LOJA 44" },
    { label: "LOJA - 47 - GUARULHOS DUTRA/CAPITAL - SP - 01.157.555/0049-59", value: "LOJA 47" },
    { label: "LOJA - 48 - GUARULHOS (VILA GALVÃO)/CAPITAL - SP - 01.157.555/0048-78", value: "LOJA 48" },
    { label: "LOJA - 49 - SÃO MATHEUS (RAGUEB CHOF)/CAPITAL - SP - 01.157.555/0050-92", value: "LOJA 49" },
    { label: "LOJA - 50 - JUNDIAI/CAPITAL - SP - 01.157.555/0051-73", value: "LOJA 50" },
    { label: "LOJA - 51 - PRAIA GRANDE I (QUIETUDE)/CAPITAL - SP - 01.157.555/0047-97", value: "LOJA 51" },
    { label: "LOJA - 53 - SOROCABA/INTERIOR - SP - 01.157.555/0054-16", value: "LOJA 53" },
    { label: "LOJA - 54 - ITATIBA/CAPITAL - SP - 01.157.555/0052-54", value: "LOJA 54" },

    { label: "LOJA - 57 - UBATUBA/CAPITAL - SP - 01.157.555/0057-69", value: "LOJA 57" },
    { label: "LOJA - 58 - PRAIA GRANDE II (AVIAÇÃO)/CAPITAL - SP - 01.157.555/0058-40", value: "LOJA 58" },
    { label: "LOJA - 59 - MATAO/INTERIOR - SP - 01.157.555/0059-20", value: "LOJA 59" },
    { label: "LOJA - 60 - JAU/OESTE - SP - 01.157.555/0060-64", value: "LOJA 60" },
    { label: "LOJA - 62 - LEME/INTERIOR - SP - 01.157.555/0062-26", value: "LOJA 62" },
    { label: "LOJA - 63 - ITAPETININGA/INTERIOR - SP - 01.157.555/0063-07", value: "LOJA 63" },
    { label: "LOJA - 64 - AVARE/OESTE - SP - 01.157.555/0064-98", value: "LOJA 64" },
    { label: "LOJA - 65 - VINHEDO/CAPITAL - SP - 01.157.555/0065-79", value: "LOJA 65" },
    { label: "LOJA - 66 - AMPARO/CAPITAL - SP - 01.157.555/0066-50", value: "LOJA 66" },
    { label: "LOJA - 67 - BERTIOGA/LITORAL - SP - 01.157.555/0067-30", value: "LOJA 67" },
  ];