import { FieldValues, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
//import { Col, Row } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { cpf as cpfValidate } from "cpf-cnpj-validator";
import { IClient } from "../../../interfaces/IClient";
import { useEffect, useState } from "react";
import ClientService from "../../../services/ClientService";
import { IWithMessageInfo } from "../../../interfaces/IWithMessage";
import GameOpportunityService from "../../../services/GameOpportunityService";
import ActionsLogedUser from "./ActionsLogedUser";

interface IProps {
  clientIsLoading: boolean;
  client?: IClient;
  onSubmitHandler: (client?: IClient) => void;
  onRemoveClient: Function;
  onGoToRegister: Function;
}

const PreRegisterForm = ({
  clientIsLoading,
  client,
  onSubmitHandler,
  onRemoveClient,
  onGoToRegister,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cpf: "",
    },
    shouldUseNativeValidation: true,
    shouldFocusError: true,
  });

  const onSubmit = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      const res = await ClientService.getClientByCpf(data.cpf);
      if (res.success) {
        onSubmitHandler(res?.body as IClient);
      } else {
        Object.keys(res.namespacedMessages).forEach((el) => {
          const errors = res.namespacedMessages[el] as IWithMessageInfo[];
          if (errors.length > 0) {
            const error = errors[0];
            const key = error.fieldName as "cpf";
            setError(key, { message: error.defaultMessage, type: "value" });
          }
        });
      }
    } catch (error) {
      alert("Tratar erro: " + JSON.stringify(error));
    }
    setIsLoading(false);
  };

  return (
    <>
      {client && client.name ? (
        <ActionsLogedUser
          client={client}
          onGoToRegister={() => onGoToRegister()}
          onRemoveClient={() => onRemoveClient()}
          clientIsLoading={clientIsLoading}
        />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} className="form_preregister">
          <Form.Group controlId="formCPF">
            <Form.Label className="form_title title">
              Digite seu CPF para participar
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              as={InputMask}
              mask="999.999.999-99"
              placeholder="___.___.___-__"
              {...register("cpf", {
                validate: (data) => {
                  //Campo obrigatório
                  if (!data) return "CPF é um campo obrigatório";

                  //Número mínimo de caractere
                  const numeros = data.replace(/[^\d]+/g, "") || "";
                  if (numeros?.length < 11) return "CPF precisa ser 11 números";

                  //Verifca se CPF é válido
                  return !cpfValidate.isValid(numeros) ? "CPF inválido" : true;
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="cpf"
              render={({ message }) => <p>{message}</p>}
            />

            <Button
              variant="secondary"
              type="submit"
              className="btn-lg w-100 mt-2 text-uppercase"
              disabled={isLoading}
            >
              {isLoading ? "Enviando…" : "ENVIAR"}
            </Button>
          </Form.Group>
        </Form>
      )}
    </>
  );
};

export default PreRegisterForm;
