import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { IClient } from "../../../interfaces/IClient";

interface IProps {
  client: IClient;
  onUpdateInfo: Function;
}

const ClientInfo = ({ client, onUpdateInfo }: IProps) => {
  return (
    <Form className="form_cadastro-user form_note">
      <h3 className="form_title title mb-4 text-center text-md-start">
        Dados do cliente
      </h3>

      <Form.Group className="mb-3" controlId="formCPF">
        <Form.Label>Seu CPF</Form.Label>
        <div className="form-control form-control-lg">{client.cpf}</div>
      </Form.Group>

      <Form.Group  className="mb-3" controlId="formCPF">
        <Form.Label>Seu Nome</Form.Label>
        <div className="form-control form-control-lg">{client.name}</div>
      </Form.Group>

      <Form.Group  className="mb-3" controlId="formCPF">
        <Form.Label>Seu E-mail</Form.Label>
        <div className="form-control form-control-lg">{client.email}</div>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formCPF">
        <Form.Label>Seu Celular</Form.Label>
        <div className="form-control form-control-lg">{client.cel}</div>
      </Form.Group>

      <Form.Group  className="mb-3" controlId="formCPF">
        <Form.Label>Sua data de nascimento</Form.Label>
        <div className="form-control form-control-lg">{client.birthday}</div>
      </Form.Group>
      
      <div className="form_actions">      
          <Button
            variant="primary"
            onClick={() => onUpdateInfo()}
            className="btn-lg w-100 mt-2"
          >
            Atualizar meus Dados
          </Button>
      </div>
    </Form>
  );
};

export default ClientInfo;
