import { Col, Container, Row, Image, Table } from "react-bootstrap";
import { SelectClientForm } from "./components/SelectClientForm";
import { useState } from "react";
import { ListNumbers } from "./components/ListNumbers";
import FooterComponent from "../../components/FooterComponent";
import { IClient } from "../../interfaces/IClient";
import DrawNumberService from "../../services/DrawNumberService";
import { IDrawNumber } from "../../interfaces/IDrawNumber";
import ClientService from "../../services/ClientService";

const CampaignNumbersPage = () => {
  const [list, setlist] = useState<IDrawNumber[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<IClient | undefined>();

  /*useEffect(() => {
    const storage = localStorage.getItem(CLIENT_STORAGE);
    let _client: any = undefined;
    if (storage && Object.keys(storage).length > 0) {
      _client = JSON.parse(storage) as IClient;
      if (_client.token) return;

      setClient(_client);
      setIsLoading(true);

      DrawNumberService.getByClientToken(_client.token).then((res) => {
        setlist(res.success ? (res.body as IDrawNumber[]) : []);
        setIsLoading(false);
      });
    }
  }, []);*/

  const onSubmitHandler = (cpf: string) => {
    setIsLoading(true);

    ClientService.getClientByCpf(cpf).then((res) => {
      if (res.success) {
        var _client = res.body as IClient;
        setClient(_client);

        DrawNumberService.getByClientToken(_client.token).then((res) => {
          setlist(res.success ? (res.body as IDrawNumber[]) : []);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro ">
            <div className="food-icons">
              <Image src="./images/icons/pao-ama.png" className="brioche" ></Image>
              <Image src="./images/icons/gota-ama.png" className="gota" ></Image>
              <Image src="./images/icons/pizza-ama.png" className="pizza" ></Image>
              <Image src="./images/icons/biscoito-ama.png" className="biscoito" ></Image>
              <Image src="./images/icons/festa-ama.png" className="festa" ></Image>
              <Image src="./images/icons/massa-ama.png" className="massa" ></Image>          
            </div>
            <div className="hero-inner">
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className=" presente" />
                  <Image src="./images/logo-brilho.png" className="img-fluid brilho" />
                </div>
                  
                <div className="form_lucknumber ">
                  <SelectClientForm
                    onSubmitHandler={(cpf) => onSubmitHandler(cpf)}
                    isLoading={isLoading}
                  />
                </div>

                {client && (
                <div className="container-numbers mt-4">
                  {list.length > 0 ? <ListNumbers itens={list} /> : `Nenhum número criado`}
                </div>
                )}

                <div className="selos  ">
                  <div className="selo-5un selo">
                    <Image
                      src="./images/5unidades.png"
                      className="img-fluid"
                    />
                    <Image
                      src="./images/massa-e-forno.png"
                      className="img-fluid massa"
                    />
                  </div>
                  <div className="selo selo-numeroextra">
                    <Image
                      src="./images/selectprod.png"
                      className="img-fluid"
                    />
                  </div>
                </div>

            </div>
          
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default CampaignNumbersPage;
