import { useFormState } from "react-hook-form";
import { IInputProps } from "../../interfaces/IInputProps";
import { IModel } from "../../interfaces/IModel";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import ErrorInput from "./ErrorInput";

interface IRadioGroupInput extends IInputProps<IModel> {
    values: Array<{
        label: string;
        value: string | boolean | number;
        default?: boolean;
    }>,
    onChange?: (event: any) => void;
}

const RadioGroupInput = ({
    label,
    attribute,
    register,
    control,
    rules = {},
    size,
    placeholder,
    values,
    type,
    ...props
}: IRadioGroupInput) => {
    const [touched, setTouched] = useState(false);
    const { errors } = useFormState({ control });

    return (
        <Form.Group className="mb-3 position-relative" controlId={`form-${attribute}`}>
            <Form.Label>{label}</Form.Label>
            {values.map((el, index) => <Form.Check
                label={el.label}
                isInvalid={touched && !!errors[attribute]}
                onFocus={() => !touched ? setTouched(true) : null}
                type='radio'
                id={`${attribute}-radio-${index}`}
                key={`${attribute}-radio-${index}`}
                value={el.value.toString()}
                {...register(attribute, rules)}
                {...props}
            />)}
            <ErrorInput error={errors[attribute]} />
        </Form.Group>
    );
};

export default RadioGroupInput;