import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from 'react-bootstrap/Alert';
import { IInvoiceCreatedInfo } from "../../../interfaces/IInvoiceCreatedInfo";

interface IProps {
  showModal: boolean;
  info?: IInvoiceCreatedInfo;
  onClose: () => void;
}

const Informations = ({ info, onClose, showModal }: IProps) => {
  if (!info) return null;

  return (
    <Modal size="lg" show={showModal} onHide={() => onClose()} className="modal-fiscalcode">
      <Modal.Header closeButton>
        <Modal.Title>NOTA CADASTRADA COM SUCESSO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
          <Alert variant="success">
            <strong> {info.drawNumbers.length} novos números da sorte</strong>
          </Alert>

          <hr />
          <div className="result-box ">
            <h3>Informações da nota fiscal</h3>

            <h4>Multiplicadores:</h4>
            <p><b>Total de produtos Suprema:</b> {info.totalProducts ? info.totalProducts : "TOTAL INDICADO PELO CLIENTE"}</p>
            <p>
              <b>* Possui algum item Farinha de Trigo Select ou Farina de 5kg:</b> {info.invoice.hasProduct ? "Sim" : "Não"}
            </p>
            
            <h4 className="text-center">Números da Sorte</h4>
            {info.drawNumbers.length > 0 && (
              <ul className="list-numbers">
                {info.drawNumbers.map((el) => (
                  <li>{`${el.number}`}</li>
                ))}
              </ul>
            )}

          <Alert variant="info">
            Você já tem um total de <b>{info.totalInvoices}</b> Notas cadastradas
          </Alert>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Informations;
