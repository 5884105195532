import { NotificationManager } from "../components/notifications";
import api from "../http-common";
import { IInvoiceCreatedInfo } from "../interfaces/IInvoiceCreatedInfo";
import { IWithMessage } from "../interfaces/IWithMessage";

const endpointPath: string = "/v1/invoice";

interface IProps {
  token: string;
  file: string;
  storeType: number;
  totalProducts: number;
  hasProduct: boolean;
}

namespace InvoiceService {
  export const add = async (params: FormData) => {
    try {
      const resp = await api.post(`${endpointPath}/add`, params, {
        headers: { "Content-Type": "multipart/form-data" },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000
      });

      NotificationManager.success(
        "Nota fiscal cadastrada com sucesso",
        "Nova nota fiscal cadastrada",
        3000
      );
      return resp?.data as IWithMessage<IInvoiceCreatedInfo>;
    } catch (e) {
      NotificationManager.error(
        "Erro no servidor ao tentar cadastrada.",
        "Erro ao cadastrada",
        3000
      );
      return e as IWithMessage<string>;
    }
  };
}

export default InvoiceService;
