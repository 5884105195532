import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterForm } from "./components/RegisterForm";
import { useEffect, useState } from "react";
import { IClient } from "../../interfaces/IClient";
import { Alert, Button, Col, Row } from "react-bootstrap";
import ClientInfo from "./components/ClientInfo";
import ClientForm from "./components/ClientForm";
import { CLIENT_STORAGE } from "../../constants/ProjectConstants";
import Informations from "./components/Informations";
import { IInvoiceCreatedInfo } from "../../interfaces/IInvoiceCreatedInfo";
import FooterComponent from "../../components/FooterComponent";
import ClientService from "../../services/ClientService";

enum TypeClientForm {
  CLIENT_INFO,
  CLIENT_UPDATE,
}

const RegisterPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [client, setClient] = useState<IClient>(state.client);
  const [showInfoInvoiceModal, setShowInfoInvoiceModal] = useState(false);
  const [invoiceCreatedInfo, setInvoiceCreatedInfo] = useState<
    IInvoiceCreatedInfo | undefined
  >(undefined);

  const [typeClientForm, setTypeClientForm] = useState<TypeClientForm>(
    client && client.name
      ? TypeClientForm.CLIENT_INFO
      : TypeClientForm.CLIENT_UPDATE
  );

  useEffect(() => {
    if (!state.client || Object.keys(state.client).length === 0) {
      navigate("/");
    } else {
      ClientService.getClientByToken(state.client.token).then((res) => {
        if (res.success) setClient(res.body as IClient);
      });
    }
  }, []);

  const onNewRegisterHandler = (info: IInvoiceCreatedInfo) => {
    setClient((prev) => ({
      ...prev,
      countOpportunitties: info.totalGameChances,
    }));
    setInvoiceCreatedInfo(info);
    setShowInfoInvoiceModal(true);
  };

  const onUpdateInfoHandler = () => {
    setTypeClientForm(TypeClientForm.CLIENT_UPDATE);
  };

  const onClientUpdateHandler = (_client: IClient) => {
    localStorage.setItem(CLIENT_STORAGE, JSON.stringify(_client));
    setClient(_client);
    setTypeClientForm(TypeClientForm.CLIENT_INFO);
  };

  const onGoBackUpdateHandler = () => {
    setTypeClientForm(
      client && client.name
        ? TypeClientForm.CLIENT_INFO
        : TypeClientForm.CLIENT_UPDATE
    );
  };

  const onGoToGameHandler = () => {
    navigate("/jogo", { state: { client } });
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro  text-md-start">
            <div className="food-icons">
              <Image src="./images/icons/pao-ama.png" className="brioche" ></Image>
              <Image src="./images/icons/gota-ama.png" className="gota" ></Image>
              <Image src="./images/icons/pizza-ama.png" className="pizza" ></Image>
              <Image src="./images/icons/biscoito-ama.png" className="biscoito" ></Image>
              <Image src="./images/icons/festa-ama.png" className="festa" ></Image>
              <Image src="./images/icons/massa-ama.png" className="massa" ></Image>          
            </div>
          <div className="hero-inner">

              <Container className="position-relative">
                  
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className=" presente" />
                  <Image src="./images/logo-brilho.png" className="img-fluid brilho" />
                </div>
                  
                
                {!client.name && (
                  <div className="cadastro_content">

                      {typeClientForm === TypeClientForm.CLIENT_INFO && (
                        <ClientInfo
                          client={client}
                          onUpdateInfo={() => onUpdateInfoHandler()}
                        />
                      )}
                      {typeClientForm === TypeClientForm.CLIENT_UPDATE && (
                        <ClientForm
                          client={client}
                          onClientUpdate={(_client: IClient) =>
                            onClientUpdateHandler(_client)
                          }
                          onGoBack={() => onGoBackUpdateHandler()}
                        />
                      )}
                  </div>
                )}

                {client.name && (
                  <Row className="cadastro_content">
                    <Col md="6" className="mb-5 md-md-0">
                      
                      <RegisterForm
                        client={client}
                        onSubmitHandler={(info: IInvoiceCreatedInfo) =>
                          onNewRegisterHandler(info)
                        }
                      />
                      {((invoiceCreatedInfo?.totalGameChances || 0) > 0 ||
                        client.countOpportunitties > 0) && (
                        <Col className="mt-5">
                          <h3 className="title form_title text-center text-md-start">
                            {`Você possui ${
                              invoiceCreatedInfo
                                ? invoiceCreatedInfo.totalGameChances
                                : client.countOpportunitties
                            } caixas da sorte te esperando`}
                          </h3>
                          <Button
                            variant="secondary"
                            onClick={() => onGoToGameHandler()}
                            className="btn-lg w-100 mt-2"
                          >
                            Abrir caixa da sorte
                          </Button>
                          <Alert variant="info mt-1 d-none">
                            <strong>{`Você possui ${
                              invoiceCreatedInfo
                                ? invoiceCreatedInfo.totalGameChances
                                : client.countOpportunitties
                            } caixas da sorte`}</strong>
                          </Alert>
                        </Col>
                      )}

                      <Informations
                        showModal={showInfoInvoiceModal}
                        info={invoiceCreatedInfo}
                        onClose={() => setShowInfoInvoiceModal(false)}
                      />
                    </Col>

                    <Col md="6">                  

                      {typeClientForm === TypeClientForm.CLIENT_INFO && (
                        <ClientInfo
                          client={client}
                          onUpdateInfo={() => onUpdateInfoHandler()}
                        />
                      )}
                      {typeClientForm === TypeClientForm.CLIENT_UPDATE && (
                        <ClientForm
                          client={client}
                          onClientUpdate={(_client: IClient) =>
                            onClientUpdateHandler(_client)
                          }
                          onGoBack={() => onGoBackUpdateHandler()}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              </Container>

              <div className="selos  ">
                  <div className="selo-5un selo">
                    <Image
                      src="./images/5unidades.png"
                      className="img-fluid"
                    />
                    <Image
                      src="./images/massa-e-forno.png"
                      className="img-fluid massa"
                    />
                  </div>
                  <div className="selo selo-numeroextra">
                    <Image
                      src="./images/selectprod.png"
                      className="img-fluid"
                    />
                  </div>
              </div>

          </div>

        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default RegisterPage;
