import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

const AboutComponent = () => {
  return (
    <section className="about sec_about">
      <Container className="position-relative">
        <div className="sec_inner sec_content" id="como-participar">

          <ol className="d-flex flex-column flex-md-row sec_list flex-md-wrap row">

            <li className="list_item col-md-3 col-lg-3">
              <Image src="./images/icon-market.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  Compre
                </div>
              </div>
            </li>
            
            <li className="list_item col-md-6 col-lg-5" >
              <Image src="./images/icon-site.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  <span className="small">Cadastre a sua nota no hotsite</span>
                  bungeetenda.com.br
                </div>  
              </div>
            </li>

            <li className="list_item col-md-3 col-lg-4">
              <Image src="./images/icon-lock.png" className="img-fluid" />
              <div className="list_content">
                <div className="list_content-inner">
                  Boa Sorte!
                </div>
              </div>
            </li>
            
          </ol>
        </div>
      </Container>
    </section>
  );
};

export default AboutComponent;
