import { Container, Image } from "react-bootstrap";
import { ListWinners } from "./components/ListWinners";
import { useEffect, useState } from "react";
import FooterComponent from "../../components/FooterComponent";
import VoucherService from "../../services/VoucherService";
import { IVoucher } from "../../interfaces/IVoucher";
import moment from "moment";

const WinnersPage = () => {
  const [list, setList] = useState<IVoucher[]>([]);

  useEffect(() => {
    const untilDate = moment().subtract(1, "day").format("YYYY-MM-DD");

    VoucherService.getListWinnersUntilDate(untilDate).then((res) => {
      let list = res.success ? (res.body as IVoucher[]) : [];

      list.sort((a, b) => a.gameOpportunity?.invoice?.client?.name?.localeCompare(b.gameOpportunity?.invoice?.client?.name || "") || 0)

      setList(list);
    });
  }, []);

  const showWinnersList = () => {
    let now = Number.parseInt(moment().format("YYYYMMDD"));

    return now > 20240901;
  };

  const getDateUntil = () => {
    return moment().subtract(1, "day").format("DD/MM");
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_cadastro page_ganhadores">
          <div className="food-icons">
              <Image src="./images/icons/pao-ama.png" className="brioche" ></Image>
              <Image src="./images/icons/gota-ama.png" className="gota" ></Image>
              <Image src="./images/icons/pizza-ama.png" className="pizza" ></Image>
              <Image src="./images/icons/biscoito-ama.png" className="biscoito" ></Image>
              <Image src="./images/icons/festa-ama.png" className="festa" ></Image>
              <Image src="./images/icons/massa-ama.png" className="massa" ></Image>          
            </div>

          <div className="hero-inner">
            
              <div className="logo_campaign ">
                <Image src="./images/logo-campanha.png" className=" presente" />
                <Image src="./images/logo-brilho.png" className="img-fluid brilho" />
              </div>
              
              {/* GANHADORES DO SORTEIO SEMANAL */}
              <div className="wrapper-ganhadores ganhadores-sorteio ">
                <h4 className="title title-ganhadores">
                  LISTA DOS GANHADORES AO FIM DA PROMOÇÃO
                </h4>
                <div className="next-draw-date d-none">
                  Próximo sorteio: 06/01/2024
                </div>
                <ul className="winners-list text-start">
                  {/* GANHADOR atual */}
                  
                  {/* <li className="winner-item winner-atual">
                    <div className="number">3</div>
                    <div className="draw-date">26/09/2024</div>
                    <div className="winner-name">
                      GABRIELSON BORGES DOS SANTOS
                    </div>
                    <div className="winner-cpf">CPF: ***.***.*5-25</div>
                    <div className="winner-number">3089822</div>
                    <div className="valor">R$30.000,00</div>
                  </li> */}

                  {/* GANHADOR ANTERIOR 
                  <li className="winner-item ">
                    <div className="number">2</div>
                    <div className="draw-date">10/10/2024</div>
                    <div className="winner-name">nome</div>
                    <div className="winner-cpf">CPF: ***.***.*9-56</div>
                    <div className="winner-number">123456789</div>
                    <div className="valor">R$30.000,00</div>
                  </li> */}
                </ul>
              </div>

              {/* GANHADORES DO SORTEIO INSTANTÂNEO */}
              <div className="wrapper-ganhadores ganhadores-vales d-none">
                <h4 className="title title-ganhadores">
                  Ganhadores do Vale-compras de R$500,00
                </h4>

                {showWinnersList() ? (
                  <>
                    <h5 className="">
                      Ganhadores de 01/09 até {getDateUntil()}{" "}
                    </h5>
                    <ListWinners itens={list} />
                  </>
                ) : (
                  <h5 className="">LISTA DISPONÍVEL A PARTIR DE 02/09</h5>
                )}
              </div>

              <div className="selos  ">
                  <div className="selo-5un selo">
                    <Image
                      src="./images/5unidades.png"
                      className="img-fluid"
                    />
                    <Image
                      src="./images/massa-e-forno.png"
                      className="img-fluid massa"
                    />
                  </div>
                  <div className="selo selo-numeroextra">
                    <Image
                      src="./images/selectprod.png"
                      className="img-fluid"
                    />
                  </div>
              </div> 
                         
          </div>
          
        </section>
      </div>
      <FooterComponent />
    </>
  );
};

export default WinnersPage;
