import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import PreRegisterForm from "./components/PreRegisterForm";
import AboutComponent from "./components/AboutComponent";
import BrandsComponent from "./components/BrandsComponent";
import FaqComponent from "./components/FaqComponent";
import RulesComponent from "./components/RulesComponent";
import FooterComponent from "./components/FooterComponent";
import { IClient } from "../../interfaces/IClient";
import {
  CLIENT_STORAGE,
  IS_PRODUCTION,
} from "../../constants/ProjectConstants";
import { useEffect, useState } from "react";
import ClientService from "../../services/ClientService";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const HomePage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState<IClient | undefined>();

  useEffect(() => {
    //localStorage.removeItem(CLIENT_STORAGE);

    const storage = localStorage.getItem(CLIENT_STORAGE);
    let _client: any = undefined;
    if (storage && Object.keys(storage).length > 0) {
      _client = JSON.parse(storage) as IClient;
      _client.countOpportunitties = 0;
      setClient(_client);

      setIsLoading(true);
      ClientService.getClientByToken(_client.token).then((res) => {
        setClient(res.success ? (res.body as IClient) : undefined);
        setIsLoading(false);
      });
    }
  }, []);

  const onSubmitHandler = async (client?: IClient) => {
    if (client) {
      localStorage.setItem(CLIENT_STORAGE, JSON.stringify(client));
    }

    navigate("/cadastro", { state: { client } });
  };

  const onRemoveClientHandler = () => {
    localStorage.removeItem(CLIENT_STORAGE);
    setClient(undefined);
  };

  const onGoToRegisterHandler = () => {
    navigate("/cadastro", { state: { client } });
  };

  const showRegisterForm = () => {
    if (!IS_PRODUCTION) return true;

    let now = Number.parseInt(moment().format("YYYYMMDD"));
    return now >= 20240901;
  };

  return (
    <>
      <div className="menupush">
        <section className="sec hero hero_sec hero_home text-center text-md-start">
            <div className="food-icons">
              <Image src="./images/icons/pao-ama.png" className="brioche" ></Image>
              <Image src="./images/icons/gota-ama.png" className="gota" ></Image>
              <Image src="./images/icons/pizza-ama.png" className="pizza" ></Image>
              <Image src="./images/icons/biscoito-ama.png" className="biscoito" ></Image>
              <Image src="./images/icons/festa-ama.png" className="festa" ></Image>
              <Image src="./images/icons/massa-ama.png" className="massa" ></Image>          
            </div>
            <div className="hero-inner">
            
                <div className="logo_campaign ">
                  <Image src="./images/logo-campanha.png" className=" presente" />
                  <Image src="./images/logo-brilho.png" className="img-fluid brilho" />
                </div>

                <div className=" form_area">
                    {showRegisterForm() ? (
                      <PreRegisterForm
                        clientIsLoading={isLoading}
                        client={client}
                        onSubmitHandler={(client?: IClient) =>
                          onSubmitHandler(client)
                        }
                        onRemoveClient={() => onRemoveClientHandler()}
                        onGoToRegister={() => onGoToRegisterHandler()}
                      />
                    ) : (<div>
                      <h3>Campanha encerrada no dia 01/10/2024</h3>
                    </div>)
                    }
                  </div>

                  <div className="selos  ">
                    <div className="selo-5un selo">
                      <Image
                        src="./images/5unidades.png"
                        className="img-fluid"
                      />
                      <Image
                        src="./images/massa-e-forno.png"
                        className="img-fluid massa"
                      />
                    </div>
                    <div className="selo selo-numeroextra">
                      <Image
                        src="./images/selectprod.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
            </div>
        </section>
      </div>
      <div className="yellow-wrapper">
        <div className="food-icons">
          <Image src="./images/icons/brioche.png" className="brioche" ></Image>
          <Image src="./images/icons/gota.png" className="gota" ></Image>
          <Image src="./images/icons/pizza.png" className="pizza" ></Image>
          <Image src="./images/icons/biscoito.png" className="biscoito" ></Image>
          <Image src="./images/icons/festa.png" className="festa" ></Image>
          <Image src="./images/icons/massa.png" className="massa" ></Image>          
        </div>
        <AboutComponent />
        {/* <BrandsComponent /> */}
        <RulesComponent />
      </div>
      
      <FaqComponent />
      <FooterComponent />
    </>
  );
};

export default HomePage;
